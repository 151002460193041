import * as React from "react"
import { Layout } from '../components';
import { Helmet } from "react-helmet";


// markup
const About = ({ location }) => {
	return (
		<Layout location={location}>
			<Helmet>
				<link href="/assets/css/how-it-works.css" rel="stylesheet" type="text/css" />

			</Helmet>

			<section data-name="layout" id={'how_it_works_page'} className={'row'}>
				<div className={'page-container justify-content-center align-items-center'}>

					<div className={'about-questions container-fluid'}>
						<ul className={'col-10 offset-1'}>
							<li>
								<div>
									<span>1</span>
								</div>
								<div>What is an NFT and why should I get one?</div>
							</li>
							<li>
								<div>
									<span>2</span>
								</div>
								<div>Non-Fungible: what does it mean?</div>
							</li>
							<li>
								<div>
									<span>3</span>
								</div>
								<div>Buying NFTs for the first time, how do I get started?</div>
							</li>
							<li>
								<div>
									<span>4</span>
								</div>
								<div>How can I trade my NFTs?</div>
							</li>
							<li>
								<div>
									<span>5</span>
								</div>
								<div>What wallet is issuing The Ephemeral Ethernal NFTs?</div>
							</li>
							<li>
								<div>
									<span>6</span>
								</div>
								<div>Which Artists have been involved so far?</div>
							</li>
						</ul>
					</div>

					<div className={'about-wrapper container-fluid '}>
						<ul className={'col-10 offset-1'}>
							<li>
								<div>
									<span>1</span>
								</div>
								<div>
									<h2 className={'about-title pb-4'}>What is an NFT<br />and why<br />should I get one?</h2>
									<p>
										NFTs (Non-Fungible Tokens) are pieces of digital content linked to the blockchain: the digital database underpinning cryptocurrencies such as Bitcoin and Ethereum. In the context of the art market, they are fundamentally changing the way people can collect and trade art, representing a step towards the democratization of art. Underpinned by a logic of access and transparency, they allow everyone to view each other's collections, through their NFT wallets. Furthermore, as art is increasingly being regarded as a commodity that functions much as a form of stocks, the NFTs you buy today have the potential of greatly increasing in value in the future.</p>
								</div>
							</li>


							<li>
								<div>
									<span>2</span>
								</div>
								<div>
									<h2 className={'about-title pb-4'}>Non-Fungible:<br />what<br />does it mean?</h2>
									<p>
										If something is fungible it means that it is completely interchangeable. For example, if you lend someone a € 5 bill and they pay you back later with a different € 5 bill, the outcome is the same as both bills have the same value. In turn, a non-fungible asset is an item that is not interchangeable, like a plane ticket. All plane tickets are unique in that they are associated with different specific data, such as a name or a destination, meaning that each one is completely unique. In the case of NFTs, what this means is that they are unique and not mutually interchangeable, and so no two NFTs are the same.
									</p>
								</div>
							</li>


							<li>
								<div>
									<span>3</span>
								</div>
								<div>
									<h2 className={'about-title pb-4'}>Buying NFTs<br />for the first time,<br />how do I get started?</h2>
									<p>
										Get a Metamask chrome extension. Load it with ETH through services that allow you to change your money into ETH like Cripto.com or Binance. Finally, approve the transaction on Metamask. And voilá!
									</p>
								</div>
							</li>

							<li>
								<div>
									<span>4</span>
								</div>
								<div>
									<h2 className={'about-title pb-4'}>How can<br />I trade my<br />NFTs?</h2>
									<p>
										Our NFTs adhere to the ERC-721 standard so you can trade them on platforms like OpenSea.
									</p>
								</div>
							</li>

							<li>
								<div>
									<span>4</span>
								</div>
								<div>
									<h2 className={'about-title pb-4'}>What wallet<br />is issuing<br />The<br />Ephemeral Ethernal<br />NFTs?</h2>
									<p>
										All NFTs are minted through the official Ephemeral Ethernal wallet with the agreement of every artist involved.
									</p>
								</div>
							</li>



						</ul>
					</div>

					{/*
					<div className={'col-10 offset-1'}>
						<SliderArtists />
					</div> */}


				</div>
			</section>

		</Layout>
	)
}

export default About
